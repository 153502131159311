import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./scss/style.scss";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Portal = React.lazy(() => import("./views/pages/page-landing/Portal"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// Admin
const LupaPasswordAdmin = React.lazy(() => import("./views/admin/lupa-password-admin/Lupa-Password-Admin"));
const LoginAdmin = React.lazy(() => import("./views/admin/login-admin/Login-Admin"));
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Mahasiswa
const DaftarMahasiswa = React.lazy(() => import("./views/mahasiswa/daftar-mahasiswa/Daftar-Mahasiswa"));
const LupaPasswordMahasiswa = React.lazy(() => import("./views/mahasiswa/lupa-password-mahasiswa/Lupa-Password-Mahasiswa"));
const LoginMahasiswa = React.lazy(() => import("./views/mahasiswa/login-mahasiswa/Login-Mahasiswa"));
const DashboardMahasiswa = React.lazy(() => import("./views/mahasiswa/dashboard/Dashboard-Mahasiswa"));
const DownloadSkp = React.lazy(() => import("./views/mahasiswa/download-skp/Download-Skp"));
const EditJenisDokumen = React.lazy(() => import("./views/mahasiswa/edit-jenis-dokumen/Edit-Jenis-Dokumen"));

// Penerjemah
const LoginPenerjemah = React.lazy(() => import("./views/penerjemah/login-penerjemah/Login-Penerjemah"));
const DashboardPenerjemah = React.lazy(() => import("./views/penerjemah/dashboard/Dashboard-Penerjemah"));
const DetailDokumen = React.lazy(() => import("./views/penerjemah/detail-dokumen/Detail-Dokumen"));
const StatusCuti = React.lazy(() => import("./views/penerjemah/status-cuti/Status-Cuti"));

class App extends React.Component {
    componentDidMount() {
        const { history } = this.props;
        window.addEventListener("popstate", () => {
            history.go(1);
        });
    }

    render() {
        return (
            <React.Suspense fallback={loading}>
                <Switch>
                <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
                <Route exact path="/portal" name="Portal" render={(props) => <Portal {...props} />} />
                <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />

                <Route exact path="/admin" name="Login Admin" render={(props) => <LoginAdmin {...props} />} />
                <Route exact path="/lupa-password-admin" name="Lupa Password" render={(props) => <LupaPasswordAdmin {...props} />} />

                <Route exact path="/member" name="Login Member" render={(props) => <LoginMahasiswa {...props} />} />
                <Route exact path="/lupa-password-member" name="Lupa Password" render={(props) => <LupaPasswordMahasiswa {...props} />} />
                <Route exact path="/daftar-member" name="Daftar" render={(props) => <DaftarMahasiswa {...props} />} />
                <Route exact path="/dashboard-member" name="Dashboard Member" render={(props) => <DashboardMahasiswa {...props} />} />
                <Route exact path="/download-skp" name="Surat Keterangan Penerjemahan" render={(props) => <DownloadSkp {...props} />} />
                <Route exact path="/edit-jenis-dokumen" name="Edit Jenis Dokumen" render={(props) => <EditJenisDokumen {...props} />} />

                <Route exact path="/penerjemah" name="Login Penerjemah" render={(props) => <LoginPenerjemah {...props} />} />
                <Route exact path="/dashboard-penerjemah" name="Dashboard Penerjemah" render={(props) => <DashboardPenerjemah {...props} />} />
                <Route exact path="/dashboard-penerjemah/detail-dokumen/:id" name="Detail Dokumen" render={(props) => <DetailDokumen {...props} />} />
                <Route exact path="/status-cuti" name="Status Cuti" render={(props) => <StatusCuti {...props} />} />

                <Route path="/" name="Welcome" render={(props) => <TheLayout {...props} />} />
                </Switch>
            </React.Suspense>
        );
    }
}

export default withRouter(App);
